<template>
    <div class="lookinfo-main">
        <div class="companty">{{ commerceInfo.name }}</div>
        <div class="tong-code">统一社会信用代码{{ commerceInfo.code }}</div>
        <div class="info-one">
            <div>
                <span style="color: #000000; font-weight: 700">{{
                    commerceInfo.name
                }}</span>
                于{{ shenqingDate }}申请加入优信立信计划，
                并签署《守信承诺书》。经立信计划官方审核，
                未发现重大失信行为，符合“{{ unitTitle }}”认定条件，
                <span style="color: #bd0406"
                    >获得“{{ unitTitle }}”称号，以资鼓励。</span
                >
            </div>
            <div>运营单位</div>
            <div>北京优信立信企业信用评估中心</div>
            <div></div>
            <div>加入时间：{{ jiaruDate }}</div>
            <div>
                检测结果：符合
                <span style="color: #bd0406">《{{ unitTitle }}加入条件》</span>
            </div>
            <img class="img-a" src="@/assets/img/nei.png" alt="" />
        </div>
        <div class="info-two">
            <div>
                <img src="@/assets/img/bi.png" alt="" />
                <span>企业已签署守信承诺书</span>
            </div>
            <div>
                <div class="chengnuo">
                    <span>{{ commerceInfo.name }}</span>
                    <span
                        >（企业/商户）加入优信立信计划后，
                        承诺严格遵守国家法律法规及行政法律规定，
                        自觉履行经营者义务，诚实守信，合规经营。
                        愿接受社会各方及管理部门的监督、检查。
                    </span>
                    <div class="qs-date">签署日期：{{ qiansuDate }}</div>
                </div>
            </div>
        </div>
        <div class="info3">
            <div class="info3-title">
                <img src="@/assets/img/jiance.png" alt="" />
                <span class="pingtai">平台监测</span>
                <span class="data-time">数据更新时间 {{ updateTime }}</span>
            </div>
            <div class="info3-jiance">
                <img src="@/assets/img/gouxuan.png" alt="" />
                <span class="xingwei">当前未发生重大失信行为</span>
                <div class="chixu">持续监测中</div>
            </div>
            <template v-for="(item, index) of jianceList">
                <div class="list" :key="index">
                    <span>{{ item.name }}</span>
                    <span>{{ item.type }}</span>
                </div>
            </template>
        </div>
        <div class="info4" v-if="commerceInfo.code">
            <div class="info3-title">
                <img src="@/assets/img/gongshang.png" alt="" />
                <span class="pingtai">工商基本信息</span>
            </div>
            <div class="jiben-info">
                <div>
                    <span>统一社会信用代码</span>
                    <span>{{ commerceInfo.code||'XXXXXXXXXXXXXXXX' }}</span>
                </div>
                <div>
                    <span>企业名称</span>
                    <span>{{ commerceInfo.name }}</span>
                </div>
            </div>
            <div class="jiben-info">
                <div>
                    <span>类型</span>
                    <span>{{ commerceInfo.type }}</span>
                </div>
                <div>
                    <span>法定代表人</span>
                    <span>{{ commerceInfo.daibiao }}</span>
                </div>
            </div>
            <div class="jiben-info">
                <div>
                    <span>所属行业</span>
                    <span>{{ commerceInfo.hangye }}</span>
                </div>
                <div>
                    <span>所属地区</span>
                    <span>{{ commerceInfo.diqu }}</span>
                </div>
            </div>
            <div class="jiben-info">
                <div>
                    <span>注册资本</span>
                    <span>{{ commerceInfo.ziben }}</span>
                </div>
                <div>
                    <span>成立日期</span>
                    <span>{{ commerceInfo.clDate }}</span>
                </div>
            </div>
            <div class="miaoshu">
                <span>经营范围</span>
                <span>{{ commerceInfo.fanwei }}</span>
            </div>
        </div>
        <aboutUsC style="margin-top: 14px;" />
    </div>
</template>

<script>
import aboutUsC from "@/components/aboutUs";
import { qrCodeInfoApi } from "@/api/index.js";
export default {
    components: {
        aboutUsC,
    },
    data() {
        return {
            unitTitle:"优信立信企业",   //单位名称
            commerceInfo: {
                code: "", //代码
                name: "公司/个体户名称XXXX", //企业名称
                type: "XXXXXXXXXXXXXXX", //类型
                daibiao: "XX", //代表人
                hangye: "XXXXXXXXX", //行业
                diqu: "XXX", //地区
                ziben: "XXXXXX", //注资
                clDate: "XXXX-XX-XX", //成立日期
                fanwei: "XXXX", //经营范围
            },
            shenqingDate: "----年--月--日", //申请时间
            jiaruDate: "----/--/--", //加入日期
            qiansuDate: "----年--月--日", //签署日期
            updateTime: "", //数据更新时间
            jianceList: [
                {
                    name: "经营状态监测",
                    type: "正常",
                },
                {
                    name: "经营异常记录监测",
                    type: "正常",
                },
                {
                    name: "重大税收违法监测",
                    type: "正常",
                },
                {
                    name: "被执行信息监测",
                    type: "正常",
                },
                {
                    name: "失信被执行人信息监测",
                    type: "正常",
                },
                {
                    name: "重大负面舆情信息监测",
                    type: "正常",
                },
            ],
            cid: "",
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        if (this.cid) {
            this.getDataFun();            
        } else {
            this.$toast("企业ID为空");
        }
        this.updateTime = this.$moment().format("YYYY-MM-DD");
    },
    methods: {
        getDataFun() {
            let self = this;
            qrCodeInfoApi(self.cid).then((res) => {
                if(res.code == 200){
                    // console.log(res.data);
                    let data = res.data;
                    self.unitTitle = data.title;
                    self.commerceInfo.name = data.comp_name;
                    self.commerceInfo.code = data.comp_info.xinyongdaima;
                    self.shenqingDate = data.joindate.replace("-", "年").replace("-", "月") + "日"; //申请时间
                    self.jiaruDate = data.joindate;
                    self.qiansuDate = data.joindate.replace("-", "年").replace("-", "月") + "日"; //签署日期
                    self.commerceInfo.type = data.comp_info.type;
                    self.commerceInfo.daibiao = data.comp_info.fadingdaibiao;
                    self.commerceInfo.hangye = data.comp_info.hangye;
                    self.commerceInfo.diqu = data.comp_info.diqu;
                    self.commerceInfo.ziben = data.comp_info.zhuceziben;
                    self.commerceInfo.clDate = data.comp_info.chenglitime;
                    self.commerceInfo.fanwei = data.comp_info.fanwei;

                    //微信分享触发事件
                    let option = self.util.shareData();
                    option.link = `${location.origin}/lookinfo/${self.cid}`;
                    option.title = data.comp_name;
                    // console.log(option)
                    self.util.weixinShareFun(option);
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
    },
};
</script>

<style lang="less" scoped>
.lookinfo-main {
    width: 375px;
    height: auto;
    background: url("~@/assets/img/look-info-back.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 19px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .companty {
        width: 100%;
        color: #ffffff;
        font-size: 16px;
        padding-left: 15px;
        box-sizing: border-box;
    }
    .tong-code {
        width: 100%;
        color: #ffffff;
        font-size: 9px;
        margin-top: 10px;
        padding-left: 15px;
        box-sizing: border-box;
    }
    .info-one {
        position: relative;
        width: 350px;
        height: auto;
        margin-top: 30px;
        background: url("~@/assets/img/info-two.png") no-repeat;
        background-size: 100% 100%;
        padding: 23px 24px 25px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .img-a {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            width: 304px;
            height: 173px;
            z-index: 1;
        }
        div {
            &:nth-child(1) {
                font-size: 14px;
                color: #666666;
                line-height: 24px;
                z-index: 10;
            }
            &:nth-child(2) {
                color: #999999;
                font-size: 11px;
                margin-top: 15px;
                margin-left: auto;
                z-index: 10;
            }
            &:nth-child(3) {
                color: #999999;
                font-size: 11px;
                margin-top: 9px;
                margin-left: auto;
                z-index: 10;
            }
            &:nth-child(4) {
                width: 321px;
                height: 1px;
                background: #dddddd;
                margin: 20px 0 11px;
                z-index: 10;
            }
            &:nth-child(5) {
                font-size: 11px;
                color: #666666;
                z-index: 10;
            }
            &:nth-child(6) {
                font-size: 11px;
                color: #666666;
                margin-top: 10px;
                z-index: 10;
            }
        }
    }
    .info-two {
        position: relative;
        width: 350px;
        height: 255px;
        background: url("~@/assets/img/info-two.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 14px;
        padding: 19px 22px 0;
        box-sizing: border-box;
        div {
            &:nth-child(1) {
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }
                span {
                    font-size: 16px;
                    color: #333333;
                    font-weight: 700;
                    margin-left: 8px;
                }
            }
            &:nth-child(2) {
                width: 304px;
                height: 173px;
                background: url("~@/assets/img/info-tow-nei.png") no-repeat;
                background-size: 100% 100%;
                margin-top: 17px;
                display: flex;
                justify-content: center;
                .chengnuo {
                    display: block;
                    width: 257px;
                    margin-top: 19px;
                    span {
                        &:nth-child(1) {
                            text-decoration: underline;
                            font-size: 13px;
                        }
                        &:nth-child(2) {
                            color: #666666;
                            font-size: 13px;
                        }
                    }
                }
                .qs-date {
                    position: absolute;
                    right: 46px;
                    bottom: 45px;
                    font-size: 11px;
                    color: #666666;
                }
            }
        }
    }
    .info3 {
        width: 350px;
        height: auto;
        background: url("~@/assets/img/info3.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 14px;
        padding-top: 20px;
        padding-bottom: 27px;
        box-sizing: border-box;
        .info3-title {
            width: 100%;
            padding: 0 21px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .pingtai {
                font-size: 16px;
                color: #333333;
                font-weight: 700;
                margin-left: 3px;
            }
            img {
                width: 20px;
                height: 20px;
            }
            .data-time {
                font-size: 11px;
                color: #666666;
                margin-left: auto;
            }
        }
        .info3-jiance {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 6px 0 20px;
            margin-top: 7px;
            margin-bottom: 12px;
            box-sizing: border-box;
            img {
                width: 20px;
                height: 20px;
            }
            .xingwei {
                font-size: 11px;
                color: #09bb07;
                margin-left: 5px;
            }
            .chixu {
                width: 90px;
                height: 24px;
                color: #09bb07;
                margin-left: auto;
                background: #e6f8e6;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
            }
        }
        .list {
            width: 313px;
            height: 30px;
            background: #e6f8e6;
            margin: 0 auto 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 11px;
            box-sizing: border-box;
            span {
                &:nth-child(1) {
                    font-size: 12px;
                }
                &:nth-child(2) {
                    font-size: 12px;
                    color: #09bb07;
                }
            }
        }
    }
    .info4 {
        width: 350px;
        height: auto;
        background: url("~@/assets/img/info4.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 24px;
        padding-top: 19px;
        padding-left: 20px;
        padding-right: 24px;
        padding-bottom: 29px;
        margin-bottom: 25px;
        box-sizing: border-box;
        .info3-title {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .pingtai {
                font-size: 16px;
                color: #333333;
                font-weight: 700;
                margin-left: 3px;
            }
            img {
                width: 20px;
                height: 20px;
            }
        }
        .jiben-info {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            div {
                display: flex;
                flex-direction: column;
                &:nth-child(1) {
                    width: 121px;
                    span {
                        &:nth-child(1) {
                            margin-bottom: 3px;
                            color: #666666;
                        }
                    }
                }
                &:nth-child(2) {
                    width: 98px;
                    span {
                        &:nth-child(1) {
                            margin-bottom: 3px;
                            color: #666666;
                        }
                    }
                }
            }
        }
        .miaoshu {
            display: flex;
            flex-direction: column;
            span {
                &:nth-child(1) {
                    margin-bottom: 3px;
                    color: #666666;
                }
            }
        }
    }
}
</style>